<template>
  <div class="container">

    <van-search class="self-search" v-model="keyword" show-action placeholder="请输入搜索关键词">
      <template #action>
        <div @click="course('search')">搜索</div>
      </template>
    </van-search>

    <!-- 课程列表 -->
    <van-empty :description="word" v-if="courseList.length == 0" />
    <div class="course-list" v-if="courseList.length > 0">
      <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onRefresh"
      >
        <div v-for="(item,i) in courseList" :key="i" class="course-list-card gray-card" @click="toBVue('/courseDetail', item.id)">
          <div class="course-list-card-image">
            <span v-if="item.tag" class="card-horn">学分</span>
            <img :src="item.cover" alt="">
          </div>
          <div class="course-list-card-text">
            <p class="title">{{item.title}}</p>
            <p class="teacher">{{item.author}} {{item.author_title}}</p>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
// import cookies from "@/libs/util.cookies";
export default {
  data(){
    return {
      courseList:[],
      keyword: '',
      word: '数据加载中...',
      loading: false,
      finished: false,
      refreshStatus: false,
      formData: {
        'offset':0,
        'limit': 10,
      }
    }
  },
  created() {
    this.course()
  },
  mounted() {
  },
  methods:{
    onRefresh() {
      this.course()
    },
    course(type = '') {
      let that = this
      if (that.refreshStatus) {
        return false
      }
      that.formData.keyword = that.keyword
      if (that.formData.offset == 0 || type == 'search') {
        that.courseList = []
      }
      that.refreshStatus = true
      this.$api.COURSE_GET(that.formData).then(res => {
        if (that.formData.offset > 0 && res.length == 0) {
          that.refreshStatus = false
          that.finished = true
        }
        if (res.length === 0) {
          that.refreshStatus = false
          that.word = '暂无数据'
          return false
        }
        res.forEach((item) => {
          that.courseList.push({
            id: item.id,
            title: item.title,
            cover: item.cover ? item.cover : '',
            author: item.author,
            author_title: item.author_title
          })
        })
        if (res.length >= that.formData.limit) {
          that.formData.offset = that.formData.offset + 15;
        } else {
          that.finished = true
        }
        that.refreshStatus = false
      })
    },
    toBVue(toPath, toid) {
      this.$router.push({path: toPath, query: {id: toid}})
    }
  }
}
</script>

<style lang='less' scoped>

.course-list-card{
  width:calc(100% - 24px);
  padding:12px;
  display:flex;
  margin-left:0;
  margin-top:16px;
}

.course-list-card-image{
  min-width:146px;
  width:146px;
  margin-right:14px;
  position: relative;
  overflow:hidden;
  border-radius:8px;
}

.course-list-card-image img{
  display:block;
  width:100%;
  max-height:100%;
}


.course-list-card-text .title{
  display:block;
  font-size:15px;
  color:#000;
  padding-bottom:5px;
}

.course-list-card-text .teacher{
  display:block;
  font-size:10px;
  color:#000;
  padding-top:6px;
  border-top:1px solid #e0e0e0;
}

</style>
